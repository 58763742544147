<template>
  <div class="main container">
    <WinnerPicker />
  </div> 
</template>l

<script>
import WinnerPicker from '@/components/WinnerPicker.vue'

export default {
  name: "Home",
  components: {
    WinnerPicker,
  }
};


</script>
